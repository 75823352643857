export default {
    data() {
        return {}
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        goDetail() {
            this.$router.push({
                //name: 'admin-my-evection',
                name: 'admin-travel-book-home'
            })
        }
    }
}
